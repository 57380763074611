import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

import '../styles/main.scss'

const LogoMenu = () => {
  const data = useStaticQuery(graphql`
    {
      oralProcare: file(
        relativePath: { eq: "Oral-ProCare-logo-2-250x100-1.png" }
      ) {
        childImageSharp {
          fixed(width: 250, height: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      lumineers: file(relativePath: { eq: "Lumineers-logo-250x150-1.png" }) {
        childImageSharp {
          fixed(width: 250, height: 150) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      SnaponSmile: file(
        relativePath: { eq: "Snap-On-Smile-logo-250x150-1.png" }
      ) {
        childImageSharp {
          fixed(width: 250, height: 150) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <Container className="options mt-5">
      <Row>
        <Col xl="4">
          <div className="logo-container text-center">
            <h3 className="site-selection">
              <a
                rel="noreferrer"
                href="https://www.oralprocare.com"
                target="_blank"
              >
                OralProCare.com
              </a>
            </h3>
            <p>Dentist recommended at-home oral hygiene products</p>
            <a
              rel="noreferrer"
              href="https://www.oralprocare.com"
              target="_blank"
            >
              <Img fixed={data.oralProcare.childImageSharp.fixed} />
            </a>
          </div>
        </Col>
        <Col xl="4">
          <div className="logo-container text-center">
            <h3 className="site-selection">
              <a
                rel="noreferrer"
                href="https://www.lumineers.com"
                target="_blank"
              >
                Lumineers.com
              </a>
            </h3>
            <p>The #1 patient-requested veneer</p>
            <a
              rel="noreferrer"
              href="https://www.lumineers.com"
              target="_blank"
            >
              <Img
                fixed={data.lumineers.childImageSharp.fixed}
                alt="Lumineers logo"
              />
            </a>
          </div>
        </Col>
        <Col xl="4">
          <div className="logo-container text-center">
            <h3 className="site-selection">
              <a
                rel="noreferrer"
                href="https://www.snaponsmile.com"
                target="_blank"
              >
                SnapOnSmile.com
              </a>
            </h3>
            <p>Dentist prescribed affordable cosmetic removable arch</p>
            <a
              rel="noreferrer"
              href="https://www.snaponsmile.com"
              target="_blank"
            >
              <Img fixed={data.SnaponSmile.childImageSharp.fixed} />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default LogoMenu
