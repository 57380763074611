import React, { useState } from 'react'
import { Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import '../styles/nav.scss'

export const Navigation = props => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <Navbar light expand="md" role="Navigation" className="nav-menu">
      <Link className="navbar-brand" to="/">
        <StaticImage
          src="../images/DDSLocator-logo-250x58.png"
          alt="Dentists"
          placeholder="none"
          layout="fixed"
          width={250}
          height={58}
        />
      </Link>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar className="ml-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/patient">
              How It Works
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/find-dentist">
              Find a Dentist
            </Link>
          </li>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default Navigation
