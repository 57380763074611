/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Navigation from './Navbar'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import LogoMenu from './logo-menu'

import '../styles/layout.scss'

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <Navigation />
        <section className="secondary-navbar">
          {/* <Container fluid={true}>
          <Row>
            <Col md="12">
              <div className="text-right link-container"><Link to="/dentist">Dentists</Link><Link to="/group-dso">DSO</Link><Link to="#">Login</Link></div>
            </Col>
          </Row>
        </Container> */}
        </section>
        <main>{children}</main>
        <footer className="footer-container">
          <Container fluid={true}>
            <Row>
              <Col xl="4" className="footer-items">
                ©{new Date().getFullYear()} Den-Mat Holdings, LLC
              </Col>
              <Col xl="4" className="footer-items-center">
                <div className="text-center footer-text-links">
                  <div>
                    <Link to="/about-us">About Us</Link>
                  </div>
                  <div>
                    <Link to="/privacy">Privacy Policy</Link>
                  </div>
                  <div>
                    <Link to="/terms-of-use">Terms of Use</Link>
                  </div>
                </div>
              </Col>
              <Col xl="4">
                <div className="social-media-container">
                  <div className="text-center">
                    <a
                      href="https://app.bullseyelocations.com/Admin/login.aspx"
                      targer="_blank"
                      rel="noreferrer"
                      style={{ color: `#fff` }}
                    >
                      Login
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
        <LogoMenu></LogoMenu>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
